import React from "react"
import Emoji from "a11y-react-emoji"

const styles = {
    containerStyles: {
        position: 'absolute',
        top: '-100%',
        left: 0,

        transform: 'rotate(-8deg)',

        borderRadius: '8px',
        margin: '0 16px',
        minWidth: '225px',
        maxHeight: '70vh',
        overflowY: 'auto',
    },
    headerStyles: {
        backgroundColor: '#2A7E19',
        padding: '8px 24px 8px 54px',
        borderTopLeftRadius: '8px',
        borderTopRightRadius: '8px',
        display: 'flex',
        alignItems: 'center',
    },
    titleStyles: {
        color: 'white',
        textTransform: 'uppercase',
        fontSize: '1.1rem',
        fontWeight: 'bold',
        fontFamily: 'sans-serif',
        letterSpacing: '3px',
        margin: '0 auto',
    },
    closeStyles: {
        cursor: 'pointer',
    },
    bodyStyles: {
        backgroundColor: 'white',
        padding: '24px',
        borderBottomLeftRadius: '8px',
        borderBottomRightRadius: '8px',
    },
}

const CovidUpdate = ({ onClickClose }) => {
    return (
        <div style={styles.containerStyles}>
            <div style={styles.headerStyles}>
                <span style={styles.titleStyles}>Postponed!</span>
                <Emoji style={styles.closeStyles} symbol="❌" label="close" onClick={onClickClose} />
            </div>
            <div style={styles.bodyStyles}>
                <p>
                    Due to the uncertainty around COVID-19, we've decided to postpone our wedding.
                    <Emoji symbol="😿" label="crying-cat" />
                </p>
                <p>Please look out for a new Save-the-Date in the mail shortly. We're looking at May 2021.</p>
                <p>
                    We hope all of our friends and family are staying healthy and positive in this stressful time.
                    <Emoji symbol="❤️" label="heart" />
                </p>
            </div>
        </div>
    )
}

export default CovidUpdate
