import React, { useState } from "react"
import { Link } from "gatsby"
import Emoji from "a11y-react-emoji"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import CovidUpdate from "../components/covidUpdate"

const styles = {
  openUpdateStyles: {
    cursor: 'pointer',
    textDecoration: 'underline blue',
    color: 'blue',
  },
}

const IndexPage = () => {
  const [showCovidUpdate, setShowCovidUpdate] = useState(true)

  return (
    <Layout>
      <SEO title="Home" />
      <div style={{ position: "relative" }}>
        <h1>We're getting married! <Emoji symbol="😻" label="heart-eyes-cat" /></h1>
        <p>Check back soon for updates.</p>
        {showCovidUpdate && <CovidUpdate onClickClose={() => setShowCovidUpdate(false)} />}
        {!showCovidUpdate && 
          <a style={styles.openUpdateStyles} onClick={() => setShowCovidUpdate(true)}>COVID-19 Update</a>
        }
      </div>
    </Layout>
  )
}

export default IndexPage
